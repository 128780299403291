import Header from "./Header";
import React, { useEffect, useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody, MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

import styled from "styled-components";
import { Button } from "reactstrap";

const Styles = styled.div``;


function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

export default function Sponsor() {
    var datai = localStorage.getItem("user-data");
    var dati = JSON.parse(datai);
    // var new_plan = dati.user.New_plan;
    // console.log(dati.user.New_plan)
    const [Nchild, setNchild] = useState('');
    const [Nyear, setNyear] = useState('');
    const [NMonth, setNMonth] = useState('');
    const [Ntotal, setNTotal] = useState('');
    const [Ninpay, setNinpay] = useState('');
    const [pkid, setpkid] = useState('');
    const [id, setId] = useState('');
    const [Rpkid, setRpkid] = useState('');
    const [Rfkid, setRfkid] = useState('');
    const [Rpending, setRpending] = useState('');
    const [Rtotal, setRtotal] = useState('');
    const [Rinst, setRinst] = useState('');
    const [fk_SponsorID, setFk_SponsorID] = useState('');
    const [No_of_child, setNo_of_child] = useState('');
    const [No_of_year, setNo_of_year] = useState('');
    const [amount, setamount] = useState('');
    const [pending_amt, setPending_amt] = useState('');
    const [paymentplan, setpaymentplan] = useState('');
    const history = useHistory();

    var tot = 0;
    var sum = 0;

    var tota = 0;
    var cho = 0;
    var dis = 0;
    var second = 0;
    var child = 0;
    var children = 0;
    var years = 0;
    var total = 0;

    const [refs] = useState({
        child: React.createRef(),
        second: React.createRef(),
        sum: React.createRef(),
        cho: React.createRef()
    });

    const [vars] = useState({
        child: 0,
        second: 0,
        sum: 0,
        cho: 0
    });

    const [value, setValue] = useState('');

    const onChange = (setIdentifierState, e) => {
        setIdentifierState(e.target.value);
        // (e.target.value)
        //  setNyear(e.target.value)
        // setNMonth(e.target.value)

        const { name, value } = e.target;
        vars[name] = value;
        // Could've used vars, but just use refs because we keep references to all 3
        child = parseInt(refs.child.current.value, 10);
        second = parseInt(refs.second.current.value, 10);
        // const opt = refs.choice.current.value;
        sum = parseInt(refs.sum.current.value, 10);
        cho = refs.cho.current.value;
        // const che = parseInt(refs.div.current.value,10);


        if (name === "child") {
            children = refs.sum.current.value = child * (second * 9000);
            tot = children;
            if (cho == "fullAmount") {
                dis = tot;
            }
            if (cho == "annual") {
                dis = tot / second;
            }
            if (cho == 'halfYearly') {
                dis = tot / (2 * second);
            }
            if (cho == 'quarterly') {
                dis = tot / (4 * second);
            }
            if (cho == 'monthly') {
                dis = tot / (12 * second);
            }
        }
        console.log(children, "children..")
        if (name === "second") {
            years = refs.sum.current.value = child * (second * 9000);
            tot = years;
            if (cho == "fullAmount") {
                dis = tot;
            }
            if (cho == "annual") {
                dis = tot / second;
            }
            if (cho == 'halfYearly') {
                dis = tot / (2 * second);
            }
            if (cho == 'quarterly') {
                dis = tot / (4 * second);
            }
            if (cho == 'monthly') {
                dis = tot / (12 * second);
            }
        }

        if (name === "sum") {
            total = refs.second.current.value = child * (second * 9000);
            tot = total;
            if (cho == "fullAmount") {
                dis = tot;
            }
            if (cho == "annual") {
                dis = tot / second;
            }
            if (cho == 'halfYearly') {
                dis = tot / (2 * second);
            }
            if (cho == 'quarterly') {
                dis = tot / (4 * second);
            }
            if (cho == 'monthly') {
                dis = tot / (12 * second);
            }
            // tot.toString();
        }
        if (name === "choice") {
            var tp = refs.sum.current.value = child * (second * 9000)
            tot = tp;
            if (cho == "fullAmount") {
                dis = tot;
            }
            if (cho == "annual") {
                dis = tot / second;
            }
            if (cho == 'halfYearly') {
                dis = tot / (2 * second);
            }
            if (cho == 'quarterly') {
                dis = tot / (4 * second);
            }
            if (cho == 'monthly') {
                dis = tot / (12 * second);
            }

            // tot.toString();
        }

        tota = tot;
        // annual = (tot/second);
        setValue(dis)
        setNTotal(tot)
    };

    const handleSelect = (e) => {

        dis = 0;
        const { name, value } = e.target;
        vars[name] = value;
        child = parseInt(refs.child.current.value, 10);
        second = parseInt(refs.second.current.value, 10);
        sum = parseInt(refs.sum.current.value, 10);

        if (name === "child") {
            children = refs.sum.current.value = child * (second * 9000);
            tot = children;
        }
        if (name === "second") {
            years = refs.sum.current.value = child * (second * 9000);
            tot = years;
        }
        if (name === "sum") {
            total = refs.second.current.value = child * (second * 9000);
            tot = total;
        }

        const cho = refs.cho.current.value;

        if (cho == "fullAmount") {
            dis = tot;
        }
        if (cho == "annual") {
            dis = tot / second;
        }
        if (cho == 'halfYearly') {
            dis = tot / (2 * second);
        }
        if (cho == 'quarterly') {
            dis = tot / (4 * second);
        }
        if (cho == 'monthly') {
            dis = tot / (12 * second);
        }
        tota = tot;
        // annual = (tot/second);
        setValue(dis)
    }
    useEffect(() => {
        var val = localStorage.getItem("value")
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.React_App_panaah}/getplan/` + localStorage.getItem("id"), requestOptions)
            .then(response => response.json())
            .then(json => {
                var Paymentplan = json.data.Payment_type
                var No_of_Child = json.data.No_of_child
                var No_of_Year = json.data.No_of_year
                var New_entry = json.data.New_entry
                var Amount = json.data.Total
                var AmtPay = json.data.AmtPay
                var Pkid = json.data.Pk_id
                var Ninpay = json.data.AmtPay
                var Fk_SponsorIDd = json.data.Fk_SponsorID
                var Pending_amt = json.data.Pending_amt
                var id = json.data.id;
                localStorage.setItem("fkid", Fk_SponsorIDd)
                localStorage.getItem("fkid")
                setPending_amt(Pending_amt)
                setFk_SponsorID(Fk_SponsorIDd)
                setNinpay(Ninpay)
                setNo_of_child(No_of_Child)
                setNo_of_year(No_of_Year)
                setamount(Amount)
                setpaymentplan(Paymentplan)
                setpkid(Pkid)
                setId(id)

                localStorage.setItem("AmtPay", AmtPay)
                localStorage.getItem("No_of_year")
                localStorage.setItem("New_entry", New_entry)
                localStorage.getItem("New_entry")
                localStorage.getItem("AmtPay")

                var amt = localStorage.getItem("amount")
                var get = localStorage.getItem("paymentplan")

            }
            )
            .catch(error => console.log('error', error));
    })

    async function postdata() {
        var val = localStorage.getItem("value")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "uid": localStorage.getItem("ID"),
            "noc": Nchild,
            "noy": Nyear,
            // "amtp": localStorage.getItem('value'),
            "amtp": Ninpay,
            "tot": Ntotal,
            "pen": Ntotal,
            "pay": NMonth,
            "New_entry": "1"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${process.env.React_App_panaah}/plans`, requestOptions)
            .then(response => response.json())
            .then(json => {
                var Fk_SponsorID = json.data.Fk_SponsorID

                var Pk_id = json.data.Pk_id
                var RTotal = json.data.Total
                var RPending_amt = json.data.Pending_amt
                var RAmtPay = json.data.AmtPay
                var payt = json.data.Payment_type
                localStorage.setItem("Rfkid", Fk_SponsorID)
                localStorage.setItem("pkid", Pk_id)
                localStorage.getItem("pkid")
                localStorage.setItem("pend", RPending_amt)

                setRfkid(Fk_SponsorID);
                setRpkid(Pk_id);
                setRpending(RPending_amt)
                setRtotal(RTotal)
                setRinst(RAmtPay)

                var message = json.message

                if (message === "Succesfully inserted") {
                    PostRazorpay()

                    //    window.open(
                    //   ' https://pages.razorpay.com/pl_HRc2yXiFYdkEzH/view'
                    //  );
                } else {
                    if (message === "")
                        alert("please Enter Valid details.")
                }
            }
            )
            .then(result => console.log(result, "resulttt afrer posting"))
            .catch(error => console.log('error', error));

    }


    async function PostRazorpay() {
        var tempDate = new Date();
        var date = tempDate.getDate() + '/' + (tempDate.getMonth() + 1) + '/' + tempDate.getFullYear();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "id": "1",
            "currency": "INR",
            "amount": localStorage.getItem('value'),
            "sponid": localStorage.getItem("Rfkid"), //Fk_SponsorID
            "planid": localStorage.getItem("pkid"), //Pk_id
            "tot": Ntotal, //Total
            "pending": localStorage.getItem("pend"), //Pending_amt
            "Date": "26/07/2021" //data
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const data = await fetch(`${process.env.React_App_panaah}/razorpay`, requestOptions)
            .then(response => response.json())

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }

        var options = {
            "key": "rzp_test_ofbpCCvwNuf5nu", // Enter the Key ID generated from the Dashboard
            "amount": data.amount.toString() + "00",// Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": 'Donation',

            "image": `${require("../Logos/Panaah_Logo-01.png")}`,
            "order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response) {

                console.log("paymentid", response.razorpay_payment_id)
                console.log("orderid", response.razorpay_order_id)
                console.log("signature", response.razorpay_signature)


                Swal.fire({
                    icon: 'success',
                    title: 'Success!...',
                    text: 'Your payment is successful!',
                })
            },
            //localStorage.getItem("name")
            "prefill": {
                "name": "Prem",
                "email": localStorage.getItem("EmailID"),
                "contact": localStorage.getItem("phone")
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Payment Failed!',

            })
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });
        //document.getElementById('rzp-button1').onclick = function(e){
        rzp1.open();
        //e.preventDefault();
        //}
    }
    // const paymentObject = new window.Razorpay(options)
    async function displayRazorpay() {
        var tempDate = new Date();
        var date = tempDate.getDate() + '/' + (tempDate.getMonth() + 1) + '/' + tempDate.getFullYear();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "id": "1",
            "currency": "INR",
            "amount": localStorage.getItem("AmtPay"),
            "sponid": fk_SponsorID, //Fk_SponsorID
            "planid": pkid, //Pk_id
            "tot": amount, //Total
            "pending": pending_amt, //Pending_amt
            "Date": date //"26/07/2021" //data
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const data = await fetch(`${process.env.React_App_panaah}/razorpay`, requestOptions)
            .then(response => response.json())

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }

        var options = {
            "key": "rzp_test_ofbpCCvwNuf5nu", // Enter the Key ID generated from the Dashboard
            "amount": data.data.amount.toString() + "00",// Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise  data.amount.toString() + "00"
            "currency": "INR",
            "name": 'Donation',

            "image": `${require("../Logos/Panaah_Logo-01.png")}`,
            "order_id": data.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response) {

                console.log("paymentid", response.razorpay_payment_id)
                console.log("orderid", response.razorpay_order_id)
                console.log("signature", response.razorpay_signature)

                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)
                Swal.fire({
                    icon: 'success',
                    title: 'Success!...',
                    text: 'Your payment is successful!',
                })
            },
            //localStorage.getItem("name")
            "prefill": {
                "name": localStorage.getItem("name"),
                "email": localStorage.getItem("EmailID"),
                "contact": localStorage.getItem("phone")
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Payment Failed!',

            })
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });
        //document.getElementById('rzp-button1').onclick = function(e){
        rzp1.open();
        //e.preventDefault();
        //}
    }
    // const paymentObject = new window.Razorpay(options)
    // paymentObject.open()
    var plan = localStorage.getItem("newplan")

    // console.log(plan)
    return (
        <div>
            <Header />
            <Styles>
                <Container>
                    <Row>
                        <Col>
                            <div >
                                <h1>Panaah: Adopt A Child</h1>
                            </div>
                            <div>
                                <p>By registering here you commit to partner with us in giving hope for tomorrow to young lives in hopeless homes today.  Panaah provides you a platform to give / contribute an amount of minimum INR 750 monthly or INR 2250 quarterly or 4500 Half Yearly or 9000 Annually towards the educational and developmental needs of children from poor families and rural communities. Select the number of children and the number of years you would like to support.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <MDBContainer>
                    <MDBRow>
                        {/* <MDBCol>
              <p>1. Select the number of children and the number of years you would like to support the child/children.</p>
              <p>2. Choose the sponsor/payment plan that suits you and submit to save your preferences.</p>
              <p>3. Once saved you can click on the pay now button to send you contributions according to your plan.</p>
              <p>4. For your convenience you can use the autopay/auto debit option by enabling the option in your profile page to send your contributions on time.</p>
              <p>5. To change your plan or any other information check your profile page.</p>
              <p>6. Email id, Pan Number and Name cannot be edited. For more information on this contact our team.</p>
            </MDBCol> */}
                        <MDBCol>
                            <h5>----------------------------------------------------------------------------------------------------------</h5>
                            <MDBRow>
                                <MDBCol>
                                    <h4>IFSC Code:</h4>
                                </MDBCol>
                                <MDBCol>
                                    <h3>UTIB0000196</h3>
                                </MDBCol>
                            </MDBRow>
                            <h5>----------------------------------------------------------------------------------------------------------</h5>
                            <MDBRow>
                                <MDBCol>
                                    <h4>Account Number:</h4>
                                </MDBCol>
                                <MDBCol>
                                    <h3>919010070924770</h3>
                                </MDBCol>
                            </MDBRow>
                            <h5>----------------------------------------------------------------------------------------------------------</h5>
                            <MDBRow>
                                <MDBCol>
                                    <h4>Beneficiary:</h4>
                                </MDBCol>
                                <MDBCol>
                                    <h3>Agape Public School</h3>
                                </MDBCol>
                            </MDBRow>
                            <h5>----------------------------------------------------------------------------------------------------------</h5>

                            <br />
                            <p >Note: <br />Payment gateway is still under development on this page, until then you can make donations using above Bank Details. We apologise for the inconvenience.<br />
                                We appreciate your support and coordination.</p>

                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <div>
                </div>
            </Styles>
        </div>
    );
}
