import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import styled from "styled-components";
// import bg from "../Logos/LoginGraphics-01-01.png";
import { breakpoints as bp } from "../GlobalStyle";

const Grid = styled.div`
  display: grid;
  grid:
    "nav header" min-content
    "nav main" 1fr / min-content 1fr;
  min-height: 80vh;
`;
const Styles = styled.div`
  .col-sm-2 {
    color: black;
  }
  .bg_image {
    padding-left: 10px;
  }
  .link_login {
    color: blue;
    margin-top: -73px;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .link_forgot {
    color: dodgerblue;
    padding-bottom: 30px;
  }
  .form {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .label {
    padding-right: 37px;
    padding-bottom: 5px;
  }
  .label {
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .button_login {
    padding-left: 200px;
    borderradius: 50;
  }
  .rcorners2 {
    border-radius: 45px;
    border: 2px solid #000000;
    padding-left: -10px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 700px;
    height: 280px;
    margin-left: 2rem;
  }
  @media (max-width: ${bp.mobile}) {
    .rcorners2 {
      border-radius: 45px;
      border: 2px solid #000000;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 300px;
      height: 320px;
      margin-left: 0rem;
      margin-top: -1.5rem;
    }
  }
`;
const Button = styled.button`
background-color: #F0F0F0;
color: black;
font-size: 15px;
width: 70px;
height: 30px;
padding: -10px 3px 5px 0px;
// box-shadow: 5px 7px #F0F0F0;
box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
border-color: #F0F0F0;
border-radius: 10px;
margin: 50px 25px 16px 30px;
cursor: pointer;
// &:disabled {
//   color: grey;
//   opacity: 0.7;
//   cursor: default;
@media (max-width: ${bp.desktop}) {
 margin: 200px 25px 16px 250px;
 padding: -10px 3px 5px 0px;
  width: 70px;
height: 30px; }
}
`;

function Forgot() {
  const onClick = (e) => {};
  const history = useHistory();
  var data = localStorage.getItem("user-data");
  var dat = JSON.parse(data);
  // console.log(dat.user.email);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailerr] = useState(false);
  const [result, setResult] = useState("");
  console.log(data);

  // function forgotHandle(e) {
  //   if (email.length < 3) {
  //     alert("type correct values");
  //   } else {
  //     alert("Good to go!!");
  //   }
  //   e.preventDefault(e);
  // }
  async function checkEmail() {
    // var a = "";
    console.log("data", email);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      email: email,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${process.env.React_App_panaah}/forgotpass`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result.data))
      .catch((error) => console.log("error", error));

    alert("Check your Email for Reset Password link!!");
  }

  return (
    <div>
      <Header />
      {/* <h1>Forgot Password</h1> */}

      <Styles>
        <div
          class="bg_image"
          style={{
            backgroundImage: ``,
            backgroundSize: "cover",
            height: "89vh",
            color: "#000000",
          }}
        >
          <h1>
            <br />
          </h1>
          <div className="rcorners2">
            <div className="col-sm-6 offset-sm-3">
              <br />
              <br />

              <label className="label1">
                Enter your Email id for Reset Password link:
              </label>
              <input
                type="email"
                placeholder="Email"
                // value={dat.user.email}
                onChange={(e) => setEmail(e.target.value)}
                // onChange={checkEmail.emailHandler}
                className="form-control"
              />

              <br />
              <button
                disabled={!email}
                onClick={checkEmail}
                className="btn btn-primary"
              >
                Get link
              </button>
            </div>
          </div>
        </div>
      </Styles>
    </div>
  );
}

export default Forgot;
