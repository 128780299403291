import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import logo from "../Logos/Panaah_Logo-01.png";
import ham from "../Logos/Hamburger-toggle.jpg"
import { breakpoints as bp } from "../GlobalStyle";

const Styles = styled.div`
  // .navbar {
  //   background-color: #000000;
  //   // height: 63px;
  // }
  // .navbar-toggler-icon {
  //   color: #fff
  // }
  .ml-auto {
    margin: 5 !important;
  }
  // .basic-navbar-nav {
  //   color: #000;
  // }
  .Brand {
    padding-left: 15px;
  }
  .logo {
    padding-left: 15px;
  }
  .HeaderDrop {
    float: left;
  }
  .links {
    padding-top: 2rem;
  }
  // .navbar-brand,
  .navbar-nav .nav-link {
    color: #fff;
    padding-right: 3.5rem;
    padding-left: 1.5rem;
    &:hover {
      color: white;
      position: sticky;
    }
    @media (max-width: ${bp.mobile}) {
      // .navbar {
      //   background-color: #000;
      // }
     
    }
  }
`;

function Header() {
  const user = JSON.parse(localStorage.getItem("user-info"));
  const username = JSON.parse(localStorage.getItem("user-data"));
  const [newplan, setNewplan] = useState('')
  const [id, setId] = useState('');
  // var new_plan = username.user.New_plan;
  // localStorage.setItem('newplan', new_plan)
  // console.log(newplan)
  const history = useHistory();
  let tokenvalue = null;

  if (user != null) {
    var token = user.token;
    getUser(token);
  }

  function getUser(tok) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${tok}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${process.env.React_App_panaah}/getuser`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        var resultdata = result.user.id;
        localStorage.setItem("newplan", result.user.New_plan)
        setNewplan(localStorage.getItem("newplan"))
        // console.log(resultdata)
        var savetoken = localStorage.setItem("id", resultdata);
        tokenvalue = localStorage.getItem("id");
        setId(resultdata)
        // console.log(id)
        var data = JSON.stringify(result);
        var savedata = localStorage.setItem("user-data", data);
        tokenvalue = localStorage.getItem("user-data");
        // localStorage.setItem('newplan', newplan)
        // console.log(tokenvalue)
        // console.log(result.user.New_plan)
      })
      .catch((error) => console.log("error", error));
  }
  // tokenvalue = localStorage.getItem("id");

  // console.log(newplan)
  function logOut() {
    localStorage.clear();
    history.push("/login");
  }
  localStorage.setItem("ID",id)
  // console.log(username.user)
  // expand="lg" variant="dark"
  return (
    <div>
      <Styles>
        <Navbar expand="lg" bg="dark" variant="dark">
          <img
            alt=""
            src={logo}
            width="90"
            height="60"
            className="d-inline-block align-top"
          />
          <Navbar.Brand className="Brand" href="/courses">
            <h3></h3>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto nav_bar_wrapper">
              {localStorage.getItem("user-info") ? (
                <>
                  <Nav className="links">
                    <Nav.Item>
                      <Nav.Link href="/sponsor">Sponsor</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="/userprofile">My Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={logOut}>Logout</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  
                </>
              ) : (
                <>
                  <Nav className="links">
                    <Nav.Item>
                      <Nav.Link href="/panaah">Home</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link exact href="/getinvolvedg">Get Involved</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link exact href="/ourstoryg">Our Story</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link exact href="/contactusg">Contact Us</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link exact href="/login">Sign In</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </>
              )}
            </Nav>
            <Nav className="HeaderDrop">
              {localStorage.getItem("user-info") ? (
                <>
                  {/* <NavDropdown title={tokenvalue}>
                    <NavDropdown.Item href="/profile">
                      My Profile
                    </NavDropdown.Item>
                    <NavDropdown.Divider />

                    <NavDropdown.Item onClick={logOut}>Logout</NavDropdown.Item>
                  </NavDropdown> */}
                </>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Styles>
    </div >
  );
}
export default Header;
