import React, { Component,useEffect } from "react";
import { BrowserRouter, Redirect, Route,useLocation } from "react-router-dom";
import ReactGa from 'react-ga';

import Homeg from "./guestcomponents/Homeg";
import Getinvolvedg from "./guestcomponents/Getinvolvedg";
import Ourstoryg from "./guestcomponents/Ourstoryg";
import Contactusg from "./guestcomponents/Contactusg";
import Terms from "./guestcomponents/Terms";
import Guestdetails from "./components/Guestdetails";
import Guestdonation from "./components/Guestdonation";
import Guestdonation2 from "./components/Guestdonation2";

import Userregistration from "./components/Userregistration";
import Login from "./components/Login";
import Sponsor from "./components/Sponsor";
import Sponsor2 from "./components/Sponsor2";
import Userprofile from "./components/Userprofile";
import Viewtransaction from "./components/Viewtransaction";
import Contact from "./components/Contact";

import Protected from "./components/Protected";
import Forgot from "./components/Forgot";
import ChangePass from "./components/ChangePass";

function App() {
  // let location = useLocation();
useEffect(() => {
 ReactGa.initialize('UA-178389359-6')
 ReactGa.pageview(window.location.pathname + window.location.search);
}, [])

  return (
    <div className="App">
      <BrowserRouter >
        <Route path="/panaah">
          <Homeg />
        </Route>
        <Route path="/getinvolvedg">
          <Getinvolvedg />
        </Route>
        <Route path="/ourstoryg">
          <Ourstoryg />
        </Route>
        <Route path="/contactusg">
          <Contactusg />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/guestdetails">
          <Guestdetails />
        </Route>
        <Route path="/guestdonation">
          <Guestdonation />
        </Route>
        <Route path="/userregistration">
          <Userregistration />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/sponsor">
          <Protected Cmp={Sponsor} />
        </Route>
        <Route path="/userprofile">
          <Protected Cmp={Userprofile} />
        </Route>
        <Route path="/viewtransaction">
          <Protected Cmp={Viewtransaction} />
        </Route>
        <Route path="/forgot">
          <Forgot />
        </Route>
        <Route path="/changepass">
          <ChangePass />
        </Route>
        <Route path="/contact">
          <Protected Cmp={Contact} />
        </Route>
      </BrowserRouter>
    </div>
  );
}

export default App;
