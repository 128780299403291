import Header from "./Header";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button } from "reactstrap";
import bg from "../Logos/LoginGraphics-01-01.png";
import { breakpoints as bp } from "../GlobalStyle";

const Styles = styled.div`
  .col-sm-2 {
    color: black;
  }
  .bg_image {
    padding-left: 10px;
  }
  .link_login {
    // color: black;
    margin-top: -27px;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .link_forgot {
    color: black;
    padding-bottom: 50px;
  }
  .form {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .label {
    padding-right: 37px;
    padding-bottom: 5px;
  }
  .label {
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .button_login {
    padding-left: 200px;
    borderradius: 50;
  }
  .container {
    padding-left: 0px;
    float: left;
  }
  .rcorners2 {
    border-radius: 45px;
    border: 2px solid #000000;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 500px;
    height: 600px;
  }
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 15px;
  }
  .rcorners22 {
    border-radius: 45px;
    border: 2px solid #f0f0f0;
    background-color: #f0f0f0;
    padding-left: 5px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 500px;
    height: 150px;
    text-align: center;
  }
  @media (max-width: ${bp.mobile}) {
    .rcorners2 {
      border-radius: 45px;
      border: 2px solid #000000;
      padding-left: 25px;
      padding-right: 20px;
      padding-top: 30px;
      padding-bottom: 10px;
      width: 300px;
      height: 600px;
    }
    .col {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 0;
      max-width: 100%;
      position: relative;
      width: 100%;
      padding-right: 0px;
      padding-left: 15px;
    }
    .rcorners22 {
      border-radius: 45px;
      border: 2px solid #f0f0f0;
      background-color: #f0f0f0;
      padding-left: 105px;
      padding-right: 20px;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 500px;
      height: 150px;
      text-align: center;
    }
    .btn btn-primary right {
      float: right;
    }
  }
`;

function Contact() {
  var data = localStorage.getItem("id");
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [res, setRes] = useState("");
  const { register, reset, errors, handleSubmit } = useForm();

  const onSubmit = async (e) => {
    e.preventDefault();
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      name: name.name,
      email: email.email,
      subject: subject.subject,
      message: message.message,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${process.env.React_App_panaah}/contact`,
      requestOptions
    )
      .then((response) => response.text())
      // .then((result) => console.log(result))
      .then((result) => setRes(result))
      .catch((error) => console.log("error", error));
    alert("Your response has been submitted.");
    history.push("/panaah");
  };
  return (
    <div>
      <Header />
      <Styles>
        <div
          class="bg_image"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            height: "89vh",
            color: "#000000",
          }}
        >
         
          <br />
       
          <div class="container">
            <div class="row">
              <div class="col">
                <div className="rcorners2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h2 class="text-center">Contact us</h2>
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        name="name"
                        placeholder="Name"
                        onChange={(e) => setName({ name: e.target.value })}
                      />
                    </div>
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => setEmail({ email: e.target.value })}
                      />
                    </div>
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="subject"
                        name="subject"
                        placeholder="Subject"
                        onChange={(e) =>
                          setSubject({ subject: e.target.value })
                        }
                      />
                    </div>
                    <div class="form-group">
                      <textarea
                        class="form-control"
                        name="message"
                        placeholder="Message"
                        rows="11"
                        onChange={(e) =>
                          setMessage({ message: e.target.value })
                        }
                      ></textarea>
                    </div>
                    <div class="form-group">
                      <Button
                        style={{ float: "right" }}
                        onClick={onSubmit}
                        disabled={!email || !name || !subject || !message}
                        class="btn btn-primary right"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styles>
    </div>
  );
}

export default Contact;
