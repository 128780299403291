import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { breakpoints as bp } from "../GlobalStyle";
import Link from "@material-ui/core/Link";
import bg from "../Logos/Panaah_HomeBG.jpg";
import img1 from "../Logos/im1.png";
import img2 from "../Logos/im2.png";
import img3 from "../Logos/im3.png";

const Styles = styled.div`
  .fig img{
    height: 100%;
    width: 100%;
    position: relative;
  }
  .fig figcaption h1{
  font-size: 4.5rem;
  color: #FFEDD9;
  font-family: Raleway;
  font-weight: bold;
  margin: auto;
  letter-spacing: 0rem;
  text-align: left;
  line-height: 1.0;
  text-shadow: 2px 1px 2px black;
  position: relative; 
  top: -2.5em;
  left: 80px;
  right: 0;
  bottom: 0;
  z-index: 1;
  }

  .row1{
    margin-bottom: 7em;
    margin-left: 1em;
  }
  .row2{
    margin-bottom: 5em;
    margin-right: 1em;
    
  }
  .row3{
    margin-left: 1em;
  }


  .bg2 {
    background-color: black;
    height: 62rem;
    margin-top: -11rem;

  }
  .bg2 h1 {
    font-size: 3.5em;
    color: #FFFFFF;
    font-family: Raleway;
    text-align: center;
    margin: auto;
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; 
  }
  .bg2 h2 {
    font-size: 1.8em;
    color: #FFFFFF;
    font-family: Raleway;
    text-align: left;
    margin: auto;
    position: absolute; 
    top: 2.5em;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; 
  }
  .bg2 p {
    font-size: 1.0em;
    color: #FFFFFF;
    font-family: Raleway;
    text-align: left;
    margin: auto;
    position: absolute; 
    top: 7em;
    left: 2em;
    right: 0;
    bottom: 0;
    z-index: 1; 
  }
  .bg2 img {
    margin-top: 5.0rem !important;
    margin-left: 10em;
    width: 15% !important;
}
  .button_login{
    border: 1;
    border-radius: 50px;

    padding: 0;
    width: 200px;
    height: 50px;
    position: relative;
    top: 5em;
    left: 28em;
  }
  .bg3 {
    background-color: #FFEDD9;
    height: 8rem;
    margin-top: -1rem;
  }
  .bg3 h1 {
    font-size: 3.0em;
    color: #000000;
    font-family: Raleway;
    text-align: center;
    margin: auto;
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; 
  }
  .bg3 h3 {
    font-size: 1.8em;
    color: #404040;
    font-family: Raleway;
    text-align: center;
    margin: auto;
    position: absolute; 
    top: 2.5em;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; 
  }
  .link_terms {
    color: dodgerblue;
    padding-bottom: 30px;
  }
  }
  
  @media (max-width: ${bp.mobile}) {
    .fig img{
      height: 100%;
      width: 100%;
      position: relative;
    }
    .fig figcaption h1 {
      position: relative;
      font-size: 6vw;
      font-family: Raleway;
      margin-top: -10%;
      top: -2em;
      left: 5vw;
    }

    .row1{
      margin-bottom: 10em;
      margin-left: 1em;
    }
    .row2{
      margin-bottom: 5em;
      margin-right: 1em;
      
    }
    .row3{
      margin-left: 1em;
    }

    .bg2 {
      background-color: black;
      height: 52rem;
      margin-top: -2rem;
      top: 4rem;
    }
    .bg2 h1 {
      font-size: 2.0em;
      color: #FFFFFF;
      font-family: Raleway;
      text-align: center;
      margin: auto;
      position: absolute; 
      top: 5px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1; 
    }
    .bg2 h2 {
      font-size: 0.8em;
      color: #FFFFFF;
      font-family: Raleway;
      text-align: left;
      margin: auto;
      position: absolute; 
      top: 4em;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1; 
    }
    .bg2 p {
      font-size: 0.7em;
      color: #FFFFFF;
      font-family: Raleway;
      text-align: left;
      margin: auto;
      position: absolute; 
      top: 8.0em;
      left: 0.2em;
      right: 0;
      bottom: 0;
      z-index: 1; 
    }
    .bg2 img {
      margin-top: 4.0rem !important;
      margin-left: 2em;
      width: 30% !important;
  }
    .button_login{
      border: 1;
      border-radius: 50px;
      background: #FFEDD9;
      padding: 0;
      width: 120px;
      height: 50px;
      position: relative;
      top: 4em;
      left: 8em;
    }
    .bg3 {
      background-color: #FFEDD9;
      height: 5rem;
      margin-top: -1rem;
  
    }
    .bg3 h1 {
      font-size: 1.5em;
      color: #000000;
      font-family: Raleway;
      text-align: center;
      margin: auto;
      position: absolute; 
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1; 
    }
    .bg3 h3 {
      font-size: 1.0em;
      color: #404040;
      font-family: Raleway;
      text-align: center;
      margin: auto;
      position: absolute; 
      top: 2.5em;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1; 
    }
`;



export default function Homeg() {
  const history = useHistory();

  return (
    <div>
      <Header />
      <Styles>
        <figure className="fig">
          <img class="figure-img" src={bg} />
          <figure>
            <figcaption >
              <h1> An initiative <br /> For a better tomorrow </h1>
            </figcaption>
          </figure>
        </figure>
        <figure className="position-relative">
          <div className="bg2">
            <h1>How do we do it ?</h1>
            <MDBContainer>
              <MDBRow className="row1">
                <MDBCol>
                  <h2>1. IMPACTING YOUNG LIVES</h2>
                  <p>According to National Sample Survey, over 20% of children below the age of 15 years in India are deprived of access to basic education This is due to to various factors like poor financial background, lack of school in their region, social barriers towards girl child and physical disability. We are building a channel for such children to access a better tomorrow.</p>
                </MDBCol>
                <MDBCol>
                  <img src={img1} />
                </MDBCol>
              </MDBRow>

              <MDBRow className="row2">
                <MDBCol>
                  <img src={img2} />
                </MDBCol>
                <MDBCol>
                  <h2>2. CHANGING THEIR MINDSET</h2>
                  <p>These children grow up in a vulnerable environment surrounded with abuse, addictions, violence and other social evils. The Pannah volunteers reach out to the children of these vulnerable sections through the communities they are placed in to provide access to education for all.</p>
                </MDBCol>
              </MDBRow>

              <MDBRow className="row3">
                <MDBCol>
                  <h2>3. GIVING A HOPE FOR TOMORROW</h2>
                  <p>Working together with you, we provide these children a platform to dream big. Pannah opens a gateway for a better quality life through higher education scholarships and employment guidance.</p>
                </MDBCol>

                <MDBCol>
                  <img src={img3} />
                </MDBCol>
              </MDBRow>
              <br />
              <br />

              {/* <div className="button_login">
                <Link href="/getinvolvedg">
                  <h12>Partner Now</h12>
                </Link>
              </div> */}

            </MDBContainer>
          </div>
        </figure>
        <figure className="position-relative">
          <div className="bg3">
            <h1>OUR VISION</h1>
            <h3>"Reaching the last, the least and the lost for a better tomorrow"</h3>
          </div>
        </figure>

        <figure className="position-relative">
          <div align="center">
            <span>© 2022 Copyright </span >&emsp;
            www.panaah.org
            <br />
            <div className="link_terms">
              <Link href="/terms">
                Terms and conditions
              </Link>
            </div>

          </div>
        </figure>

      </Styles>
    </div>
  );
}
