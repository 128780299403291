import React from 'react';
import Header from '../components/Header';

import { breakpoints as bp } from "../GlobalStyle";

import styled from "styled-components";

const Styles = styled.div` 
    .tou h1{
        text-align: center;
        font-family: Raleway;
        font-size: 3em;
        margin-top: 1em;
        
    }
    .toup p{
        margin: 1em 5em 1em 5em;
        font-family: Raleway;
        font-size: 1.5em;
        text-align: justify;
    }
    .pp h1{
      text-align: center;
      font-family: Raleway;
      font-size: 3em;
      margin-top: 1em;
      
  }
  .ppp p{
      margin: 1em 5em 1em 5em;
      font-family: Raleway;
      font-size: 1.5em;
      text-align: justify;
  }
  .orp h1{
    text-align: center;
    font-family: Raleway;
    font-size: 3em;
    margin-top: 1em;
    
}
.orpp p{
    margin: 1em 5em 1em 5em;
    font-family: Raleway;
    font-size: 1.5em;
    text-align: justify;
}

    @media (max-width: ${bp.mobile}) {
    .tou h1{
        text-align: center;
        font-family: Raleway;
        font-size: 2em;
        margin-top: 0.5em;
        
    }
    .toup p{
        margin: 1em 1em 1em 1em;
        font-family: Raleway;
        font-size: 1em;
        text-align: justify;
    }
    .pp h1{
      text-align: center;
      font-family: Raleway;
      font-size: 2em;
      margin-top: 0.5em;
      
  }
  .ppp p{
      margin: 1em 1em 1em 1em;
      font-family: Raleway;
      font-size: 1em;
      text-align: justify;
  }
  .orp h1{
    text-align: center;
    font-family: Raleway;
    font-size: 2em;
    margin-top: 0.5em;
    
}
.orpp p{
    margin: 1em 1em 1em 1em;
    font-family: Raleway;
    font-size: 1em;
    text-align: justify;
}

    }

`;


export default function Terms() {

  return (
    <div>
      <Header />
      <Styles>
        <div className="tou">
          <h1> Terms of Use </h1>
        </div>
        <div className="toup">
          <p>
            The content and data found on Panaah website, including images and text, are
            property of APS Society and may not be redistributed, copied or modified without
            express written permission.
            Using images found on any Panaah website, linking to any APS Society website or in
            some other manner framing or including any of the materials contained on any APS
            Society website without the prior written permission of APS Society is strictly
            prohibited. Such activity could result in legal action. Decisions regarding use may be
            revised or rescinded without prior notice.
            While APS Society makes every effort to provide accurate information, the content
            provided on the website is "as is" and APS Society makes no warranties regarding
            the content on the website. APS Society will not in any way be held responsible for
            any damages or loss arising out of the use of the information on the website.
            APS Society websites may contain links to other sites. APS Society is not responsible
            for the privacy practices or the content of external websites.
            By using this website, sharing your details with us, registering on the website, by
            sponsoring a child or showing interest in sponsorships, you also agree to receive
            communication from us in the form of emails, SMS, letters and phone calls. The
            communications that you receive will pertain to updates about APS Society work,
            payment details / queries and information / letters from your sponsored child. You
            agree that the same shall not be deemed to be a violation of applicable
            telecommunication regulations.
          </p>
        </div>
        <div className="pp">
          <h1> Privacy Policy </h1>
        </div>
        <div className="ppp">
          <p>
            APS Society is committed to protecting the privacy of the children and adults living in
            communities where APS Society’s works. As part of this commitment, APS Society
            strictly prohibits the use of the child photographs used in this website for any other
            purpose, personal or commercial.

            APS Society is also committed to protecting the privacy of its donors, supporters,
            employees, registered members and other stakeholders. We value the trust of those
            we deal with and of the public and recognise that maintaining this trust requires that
            we be transparent and accountable in how we treat the personal information that
            you choose to share with us.

            During the course of our various activities, we frequently gather and use personal
            information. Anyone from whom we collect such personal information should expect
            that it will be carefully protected and that any use of or dealings with this personal
            information is subject to express or implied consent.

            APS Society has voluntarily decided to adhere to best practices for the collection, use
            and disclosure of personal information as outlined in this policy.
          </p>
        </div>
        <div className="orp">
          <h1>Our Refund Policy</h1>
        </div>
        <div className="orpp">
          <p>
            The refund is only possible if the request is made within 30 days and for all online
            transactions and credit card payments, in case a request is made for refund

            To raise a refund request:
            • Please send a mail to finance@aps-society.org with the transaction ID so that
            the transaction could be easily found.
            • Please attach the screen shot of the transaction.
            • And please do specify if any urgency in the refund .
            Amount is refunded within 5-7 working days. Transactions may be disputed anytime
            within up to 120 (one hundred twenty) days, from the date of transaction by a buyer,
            as per the Card Payment Network Rules. If there is any problem with the recipient
            bank it will be notified so the issue can be taken up with the recipients bank.
          </p>
        </div>
      </Styles>
    </div>
  );
}
