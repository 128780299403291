import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBDataTable } from "mdbreact";
import moment from 'moment'

import styled from "styled-components";
import Header from "./Header";

const Styles = styled.div`

        
  }`
export default function Viewtransaction() {

  const [data, setData] = useState([]);

  useEffect(() => {
    console.log(localStorage.getItem("fkidd"), "vewfkid")
    console.log(localStorage.getItem("pkid"), "viewpkid")

    var checkin = localStorage.getItem("checkin")
    var checkIn = moment(checkin).format("L")
    var checkinn = moment(checkIn).format('yyyy-MM-D')

    var checkout = localStorage.getItem("checkout")
    var checkOut = moment(checkout).format("L")
    var checkoutt = moment(checkOut).format('yyyy-MM-D')

    console.log(checkinn, "checkin")
    console.log(checkoutt)
    console.log(localStorage.getItem("checkout"))
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${process.env.React_App_panaah}/trget/` + (localStorage.getItem("fkidd")) + '/' + localStorage.getItem("pkid") + '/' + checkinn + '/' + checkoutt, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        var Date_tr = json.data.Display_Date
        console.log("date..", Date_tr)
        console.log(json.data, "data..");
        setData(json.data);

      })
      .catch((error) => console.log("error", error));
  }, []);

  const rowInfo = () => {
    console.log(rowInfo, "checked")
  }

  const datas = {
    columns: [
      {
        label: 'Transaction ID',
        field: 'Orderid',
        width: 187,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'TransactionID',
        },
      },
      {
        label: 'Transaction Date',
        field: 'Date_tr',
        width: 270,
      },
      {
        label: 'Amount',
        field: 'Transaction_amount',
        width: 200,
      },
      {

        label: 'Status',
        field: 'Transaction_status',
        sort: 'asc',
        width: 100,
      },

    ],
    rows: data.map((data) => {
      return {
        Orderid: data.Orderid,
        Date_tr: data.Display_Date,
        Transaction_amount: data.Transaction_amount,
        Transaction_status: data.Transaction_status,
      }
    }),
  };
  const [checkbox1, setCheckbox1] = React.useState('');
  const showLogs2 = (e) => {
    setCheckbox1(e);
  };

  return (
    <div>
      <Header />
      <Styles>
        <div className="rcorners1">
          <MDBContainer>
            <MDBRow center className="mt-3 pt-1">
              <MDBCol md="12">
                <MDBDataTable scrollX striped bordered small hover entriesOptions={[5, 10]} entries={5} pagesAmount={4} data={datas} fullPagination searchTop searchBottom={false}/>

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
       
      </Styles>
    </div>
  );

}
