import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom"
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { MDBInputGroup } from "mdbreact";
import Header from "../components/Header";
import img1 from "./Images/gray.jpg";
import { breakpoints as bp } from "../GlobalStyle";
import Swal from 'sweetalert2';

export default function Getinvolvedg() {
  const [NewuserEmail, setNewuserEmail] = useState('');
  const [GuestuserEmail, setGuestuserEmail] = useState('');
  const [VolunteerEmail, setVolunteerEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState('');
  const [messageguest, setMessageguest] = useState('');
  const [messagevol, setMessagevol] = useState('');
  const history = useHistory();
  localStorage.setItem("Newuseremail", NewuserEmail)
  localStorage.setItem("GuestuserEmail", GuestuserEmail)

  const emailRegex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

  async function register() {
    const email = NewuserEmail
    console.log(email)

    if (emailRegex.test(email)) {
      setIsValid(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "EmailID": NewuserEmail
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      await fetch("https://panaah-api.herokuapp.com/mailcheck", requestOptions)
        .then(response => response.json())
        .then(json => {
          var message = json.message
          var otp_verified = json.otp_verified
          console.log(otp_verified)
          console.log(json)
          if (message === "The E-mail already in use") {
            if (message === "The E-mail already in use" && otp_verified === "0") {
              //message = "You have already registered.Please complete OTP validation"
              alert("You have already registered.Please complete OTP validation.")
              Swal.fire({
                title: 'Enter valid OTP',
                input: 'text',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                preConfirm: function (email) {
                  return new Promise(function (resolve, reject) {
                    setTimeout(function () {
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/json");
                      var raw = JSON.stringify({
                        "otp": email,
                        "email": localStorage.getItem("Newuseremail")
                      });
                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                      };
                      fetch("https://panaah-api.herokuapp.com/otpcheck", requestOptions)
                        .then(response => response.json())
                        .then(json => {
                          var message = json.message
                          if (message === "correct otp") {
                            window.location = "./login";
                          } else {
                            resolve()
                            alert("Enter Valid OTP.");
                          }
                          console.log(json, "result in otp ver")
                        })
                        //.then(result => console.log(result ,"result in otp ver")
                        .catch(error => console.log('error', error));
                    }, 2000)
                  })
                },
                allowOutsideClick: false
              }).then(function () {

              })
            } else {
              if (message === "The E-mail already in use" && otp_verified === "1") {
                alert("User is already registered. Please sign in")
                history.push('/login')
              }
            }

          } else {
            history.push('/userregistration')
          }
          console.log(message, "json.........")
        })
        .catch(error => console.log('error', error));
    } else {
      setIsValid(false);
      setMessage('Please enter a valid email!');
    }
  }

  function guest() {
    const email = GuestuserEmail
    if (emailRegex.test(email)) {
      setIsValid(true);
      history.push('/guestdetails')
    } else {
      setIsValid(false);
      setMessageguest('Please enter a valid email!');
    }
  }

  function volunteer() {
    const email = VolunteerEmail
    if (emailRegex.test(email)) {
      setIsValid(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "email": VolunteerEmail
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch("https://panaah-api.herokuapp.com/volunteer", requestOptions)
        .then(response => response.text())
        .then(result => alert("Thank you for volunteering with us. Our team will get back to you shortly."))
        .catch(error => console.log('error', error));
    } else {
      setIsValid(false);
      setMessagevol('Please enter a valid email!');
    }
  }

  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col>
            <div>
              <h4>IMPORTANT </h4>
              <h6>
                - To be a regular partner to sponsor a child, please click SIGN UP</h6>
              <h6>
                - To donate a one time payment as a guest, please click SUBMIT </h6>
              <h6>
                - To join our team as a volunteer, please enter your details and click JOIN </h6>
            </div>
          </Col>
        </Row>
      </Container>

      <MDBContainer>
        <MDBRow>
          <MDBCol size='md' className='col-example'>
            <div className="rcorners1">
              <h3>New Partner Registration</h3>
              <div>
                <MDBInputGroup containerClassName="mb-3" prepend="Email ID" hint=""
                  type="email" onChange={e => setNewuserEmail(e.target.value)} required />
              </div>
              <div className="bun">
                <button disabled={!NewuserEmail} onClick={register} style={{ backgroundColor: "#FFEDD9", width: 150, height: 50, borderRadius: 50 }}>SIGN UP</button>
              </div>
              <div className={`message ${isValid ? 'success' : 'error'}`}>
                {message}
              </div>
            </div>
          </MDBCol>

          <MDBCol size='md' className='col-example'>
            <div className="rcorners2">
              <h3>Guest Sponsor</h3>
              <div>
                <MDBInputGroup style={{}} containerClassName="mb-3" prepend="Email ID" hint="" onChange={e => setGuestuserEmail(e.target.value)} />
              </div>
              {/* style={{ paddingLeft: '300px', }} */}
              <div className="bun">
                <button disabled={!GuestuserEmail} onClick={guest} style={{ backgroundColor: "#FFEDD9", width: 150, height: 50, borderRadius: 50 }}> SUBMIT</button>
              </div>
              <div className={`message ${isValid ? 'success' : 'error'}`}>
                {messageguest}
              </div>
            </div>
          </MDBCol>

          <MDBCol size='md' className='col-example'>
            <div className="rcorners3">
              <div >
                <h3>Volunteer</h3>
              </div>
              <div>
                <MDBInputGroup style={{ backgroundColor: '#FFEDD9' }} containerClassName="mb-3" prepend="Email ID" hint="" type="email" onChange={e => setVolunteerEmail(e.target.value)}
                  name="email" />
              </div>
              <div className="bun" >
                <button disabled={!VolunteerEmail} onClick={volunteer}
                  style={{ backgroundColor: "#FFEDD9", width: 150, height: 50, borderRadius: 50 }}>JOIN</button>
              </div>
              <div className={`message ${isValid ? 'success' : 'error'}`}>
                {messagevol}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
