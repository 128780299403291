////Guest Donation with Bank Account Details for Donation

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "reactstrap";
import { Container, Row, Col } from "react-bootstrap";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBDataTable } from 'mdbreact';
import Swal from 'sweetalert2';

import Header from "./Header";
import { breakpoints as bp } from "../GlobalStyle";

const Styles = styled.div`

  @media (max-width: ${bp.mobile}) {

`;

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

export default function Guestdonation2() {
    var datai = localStorage.getItem("user-data");
    var dati = JSON.parse(datai);

    const history = useHistory();

    const [uid, setUid] = useState('');
    const [guestdet, setGuestdet] = useState('');
    const [Nchild, setNchild] = useState('');
    const [Nyear, setNyear] = useState('');
    var tot = 0;
    var sum = 0;

    var tota = 0;
    var cho = 0;
    var dis = 0;
    var second = 0;
    var child = 0;
    var children = 0;
    var years = 0;
    var total = 0;

    const [refs] = useState({
        child: React.createRef(),
        second: React.createRef(),
        sum: React.createRef(),
        // cho: React.createRef()
    });

    const [vars] = useState({
        child: 0,
        second: 0,
        sum: 0,
        // cho :0,
        totall: 0,
    });

    const [value, setValue] = useState('');
    const [amount, setamount] = useState('');

    const onChange = (setIdentifierState, e) => {
        setIdentifierState(e.target.value);
        const { name, value } = e.target;
        vars[name] = value;
        // Could've used vars, but just use refs because we keep references to all 3
        child = parseInt(refs.child.current.value, 10);
        second = parseInt(refs.second.current.value, 10);

        sum = parseInt(refs.sum.current.value, 10);

        if (name === "child") {
            children = refs.sum.current.value = child * (second * 9000);
            tot = children;
            dis = tot;

        }

        if (name === "second") {
            years = refs.sum.current.value = child * (second * 9000);
            tot = years;
            dis = tot;

        }
        if (name === "sum") {
            total = refs.second.current.value = child * (second * 9000);
            tot = total;
            dis = tot;
        }
        if (name === "choice") {
            var tp = refs.sum.current.value = child * (second * 9000)
            tot = tp;
            dis = tot;
        }

        tota = tot;
        setValue(dis)
    };

    const handleSelect = (e) => {
        dis = 0;

        const { name, value } = e.target;
        vars[name] = value;
        child = parseInt(refs.child.current.value, 10);
        second = parseInt(refs.second.current.value, 10);
        sum = parseInt(refs.sum.current.value, 10);

        if (name === "child") {
            children = refs.sum.current.value = child * (second * 9000);
            tot = children;
        }
        if (name === "second") {
            years = refs.sum.current.value = child * (second * 9000);
            tot = years;
        }
        if (name === "sum") {
            total = refs.second.current.value = child * (second * 9000);
            tot = total;
        }
        dis = tot;
        tota = tot;
        setValue(dis)
    }

    async function postamount() {
        var val = localStorage.getItem("value")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "uid": localStorage.getItem("id"),
            "noc": "1",
            "noy": "1",
            "amtp": "1",
            "tot": amount,
            "pen": "1",
            "pay": "1",
            "New_entry": "1"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${process.env.React_App_panaah}/plans`, requestOptions)
            .then(response => response.json())
            .then(json => {
                var message = json.message
                if (message === "Succesfully inserted") {
                    amountRazorpay()
                } else {
                    if (message === "")
                        alert("Please enter valid details.")
                }
            }
            )
            .then(result => console.log(result, "result after posting"))
            .catch(error => console.log('error', error));
    }

    async function postdata() {
        var val = localStorage.getItem("value")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "uid": localStorage.getItem("id"),
            "noc": Nchild,
            "noy": Nyear,
            "amtp": '',
            "tot": localStorage.getItem('value'),
            "pen": "",
            "pay": "",
            "New_entry": "1"
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(`${process.env.React_App_panaah}/plans`, requestOptions)
            .then(response => response.json())
            .then(json => {
                setUid(json.data.Fk_SponsorID)
                setGuestdet(JSON.stringify(json))
                var message = json.message

                if (message === "Succesfully inserted") {
                    displayRazorpay()

                } else {
                    if (message === "")
                        alert("Please enter valid details.")
                }
            }
            )
            .then(result => console.log(result, "result after posting"))
            .catch(error => console.log('error', error));

    }

    async function displayRazorpay() {
        var tempDate = new Date();
        var date = tempDate.getDate() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getFullYear();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "id": "1",
            "currency": "INR",
            "amount": localStorage.getItem('value'),
            "sponid": localStorage.getItem("id"), //Fk_SponsorID
            "planid": "1", //Pk_id
            "tot": localStorage.getItem('value'), //Total
            "pending": localStorage.getItem('value'), //Pending_amt
            "Date": date //"26/07/2021" //data
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const data = await fetch(`${process.env.React_App_panaah}/razorpay`, requestOptions)
            .then(response => response.json())
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }

        var options = {
            "key": 'rzp_test_ofbpCCvwNuf5nu', // Enter the Key ID generated from the Dashboard
            "amount": data.data.amount.toString(),// Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise

            "currency": "INR",
            "name": localStorage.getItem("statename"),

            "image": `${require("../Logos/Panaah_Logo-01.png")}`,
            "order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response) {
                alert(response.razorpay_payment_id);
                alert(response.razorpay_order_id);
                alert(response.razorpay_signature)
            },
            "prefill": {
                "name": localStorage.getItem("statename"),
                "email": localStorage.getItem("GuestuserEmail"),
                "contact": localStorage.getItem("mobileN")
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });
        //document.getElementById('rzp-button1').onclick = function(e){
        rzp1.open();
        //e.preventDefault();
        //}
    }
    async function amountRazorpay() {

        var tempDate = new Date();
        var date = tempDate.getDate() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getFullYear();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "id": "1",
            "currency": "INR",
            "amount": amount,
            "sponid": localStorage.getItem("id"), //Fk_SponsorID
            "planid": "1", //Pk_id
            "tot": amount, //Total
            "pending": amount, //Pending_amt
            "Date": date //data
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const data = await fetch(`${process.env.React_App_panaah}/razorpay`, requestOptions)
            .then(response => response.json())

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }

        var options = {
            "key": 'rzp_test_ofbpCCvwNuf5nu', // Enter the Key ID generated from the Dashboard

            "amount": amount.toString() + "00",// Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": localStorage.getItem("statename"),

            "image": `${require("../Logos/Panaah_Logo-01.png")}`,
            "order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response) {

                console.log("paymentid", response.razorpay_payment_id)
                console.log("orderid", response.razorpay_order_id)
                console.log("signature", response.razorpay_signature)


                Swal.fire({
                    icon: 'success',
                    title: 'Success!...',
                    text: 'Your payment was successful!',
                })
            },
            "prefill": {
                "name": localStorage.getItem("statename"),
                "email": localStorage.getItem("GuestuserEmail"),
                "contact": localStorage.getItem("mobileN")
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });
        //document.getElementById('rzp-button1').onclick = function(e){
        rzp1.open();
        //e.preventDefault();
        //}
    }
    const datas = {
        columns: [
            {
                label: 'IFSC Code',
                field: 'ifsc',
                width: 187,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'IFSC',
                },

            },
            {
                label: 'Account Number',
                field: 'accno',
                width: 270,
            },
            {
                label: 'Beneficiary',
                field: 'benef',
                width: 200,
            },


        ],
        rows: [
            {
                ifsc: 'UTIB0000196',
                accno: '919010070924770',
                benef: 'Agape Public School',
            },
        ],
    };

    return (
        <div>
            <Header />
            <Styles>
                <Container>
                    <Row>
                        <Col>
                            <div >
                                <h1> Adopt A Child</h1>
                            </div>
                            <div>
                                <p>Panaah provides you the platform and privilege of contributing / gifting a grant of your convenience without a periodic commitment. A one time gift of any value can still mean a lot to give hope for tomorrow to young lives in hopeless homes today. <b>(Minimum contribution of INR 1000)</b></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <MDBContainer>
                    <h5>----------------------------------------------------------------------------------------------------------</h5>
                    <MDBRow>
                        <MDBCol>
                            <h4>IFSC Code:</h4>
                        </MDBCol>
                        <MDBCol>
                            <h3>UTIB0000196</h3>
                        </MDBCol>
                    </MDBRow>
                    <h5>----------------------------------------------------------------------------------------------------------</h5>
                    <MDBRow>
                        <MDBCol>
                            <h4>Account Number:</h4>
                        </MDBCol>
                        <MDBCol>
                            <h3>919010070924770</h3>
                        </MDBCol>
                    </MDBRow>
                    <h5>----------------------------------------------------------------------------------------------------------</h5>
                    <MDBRow>
                        <MDBCol>
                            <h4>Beneficiary:</h4>
                        </MDBCol>
                        <MDBCol>
                            <h3>Agape Public School</h3>
                        </MDBCol>
                    </MDBRow>
                    <h5>----------------------------------------------------------------------------------------------------------</h5>

                    <br/>
                    <p >Note: <br/>Payment gateway is still under development on this page, until then you can make donations using above Bank Details. We apologise for the inconvenience.<br/>
                    We appreciate your support and coordination.</p>
                </MDBContainer>
         
            </Styles>
        </div >
    );
}


