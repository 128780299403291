import React, { Component } from "react";
import Header from "./Header";
import styled from "styled-components";
import Swal from 'sweetalert2';
import { MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBInput } from 'mdbreact';
import Grid from '@material-ui/core/Grid';
 
import { breakpoints as bp } from "../GlobalStyle";

const Styles = styled.div`
 

  @media (max-width: ${bp.mobile}) {
   
  }
`;

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
const PanRegex = RegExp(
    RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)
  );
const formValid = ({ formErrors, ...rest }) => {
    let valid = true;
    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });
    // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });
    return valid;
};

export default class Guestdetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: '',
            token: '',
            isLoaded: false,
            username: "",
            password: "",
            message: "",
            open: false,
            firstName: null,
            lastName: null,
            email: localStorage.getItem("GuestuserEmail"),
            password: null,
            panid: null,
            mobileNo: null,
            formErrors: {
                firstName: "",
                lastName: "",
                UserName: "",
                email: "",
                password: "",
                panid: "",
                mobileNo: "",
            }
        };
    }
    handleSubmit = e => {
        e.preventDefault();

        if (formValid(this.state)) {
            console.log(`
            --SUBMITTING--
            First Name: ${this.state.firstName}
            Last Name: ${this.state.lastName}
            Email: ${this.state.email}
            Password: ${this.state.password}
          `);
        } else {
            console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
        }
        this.getdata();
    };

    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };

        switch (name) {
            case "firstName":
                formErrors.firstName =
                    value.length < 3 ? "minimum 3 characters required" : "";
                break;
            case "lastName":
                formErrors.lastName =
                    value.length < 3 ? "minimum 3 characters required" : "";
                break;
            case "UserName":
                formErrors.lastName =
                    value.length < 3 ? "minimum 3 characters required" : "";
                break;
            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                break;
            case "panid":
                formErrors.panid = PanRegex.test(value)
                    ? ""
                    : "Invalid PAN";
                break;
            case "mobileNo":
                formErrors.mobileNo =
                    value.length < 10 ? "minimum 10 digits required" : "";
                break;
            default:
                break;
        }

        this.setState({ formErrors, [name]: value }, () => console.log(this.state));
    };

    setUsername = event => {
        this.setState({
            username: event.target.value
        });
    };

    setPassword = event => {
        this.setState({
            password: event.target.value
        });
    };
    sayHello = () => {
        this.props.history.push("/userregistration");
    }
    saypay = () => {
        this.props.history.push("/guestdonation");
    }
    forgotpassword = () => {
        this.props.history.push("/forgotpassword");
    }
    componentDidMount() {
        // For initial data
        // this.getdata();
    }
    getdata = () => {
        localStorage.setItem("statename", this.state.firstName)
        localStorage.setItem("mobileN", this.state.mobileNo)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "FirstName": this.state.firstName,
            "LastName": this.state.lastName,
            "EmailID": localStorage.getItem("GuestuserEmail"),
            "Phonenumber": this.state.mobileNo,
            "panid": this.state.panid
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(`${process.env.React_App_panaah}/guest`, requestOptions)
            .then(response => response.json())
            .then(json => {
                var resdata = json.otp;
                var id = json.data.id
                localStorage.setItem("id", id)
                var lid = localStorage.getItem("id")
               
                var savetoken = localStorage.setItem("otp", resdata)
                var tokenvalue = localStorage.getItem("otp")
               
                if (tokenvalue == "undefined") {
                    // return null;
                } else {
                    Swal.fire({
                        title: 'Enter the OTP received in your e-mail',
                        input: 'text',
                        showCancelButton: true,
                        confirmButtonText: 'Submit',
                        showLoaderOnConfirm: true,
                        preConfirm: function (email) {
                            return new Promise(function (resolve, reject) {
                                setTimeout(function () {
                                    if (email === tokenvalue) {
                                        window.location = "/guestdonation";
                                        reject('This email is already in use.')
                                    } else {

                                        resolve()
                                        alert("Enter valid OTP");
                                    }
                                }, 2000)
                            })
                        },
                        allowOutsideClick: false
                    }).then(function () {

                        // window.location = "./guestprofile";
                    })
                }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error))
    }
    handleClose = () => {
        this.setState({
            open: false
        });
    };
    render() {
        const { formErrors, items } = this.state;
      

        return (
            <div>
                <Header />
                <Styles>
                    <MDBContainer >
                        <MDBRow>
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                                style={{ minHeight: '80vh' }}>
                                <div className='textmain'>
                                    <h3 >Guest Information Page</h3>
                                </div>
                                <MDBCard >
                                    <MDBCardBody className="mx-4 mt-4" >
                                        <form onSubmit={this.handleSubmit} noValidate>
                                            <div class="form-row">
                                                <div class=" col-md-6">
                                                    <div className="firstName">
                                                        < label htmlFor="firstName">First Name* </ label>
                                                    </div>
                                                    <div>
                                                        <MDBInput style={{ borderColor: 'gray', borderWidth: 1, maxWidth: '450px' }}
                                                            className={formErrors.firstName.length > 0 ? "error" : null}
                                                            hint="First Name"
                                                            type="text"
                                                            name="firstName"
                                                            noValidate
                                                            onChange={this.handleChange}
                                                        />
                                                        {formErrors.firstName.length > 0 && (
                                                            <span style={{ color: 'red' }} className="errorMessage">{formErrors.firstName}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div class=" col-md-6">
                                                    <label htmlFor="lastName">Last Name* </label>
                                                    <MDBInput style={{ borderColor: 'gray', borderWidth: 1, maxWidth: '450px' }}
                                                        className={formErrors.lastName.length > 0 ? "error" : null}
                                                        hint="Last Name"
                                                        type="text"
                                                        name="lastName"
                                                        noValidate
                                                        onChange={this.handleChange}
                                                    />
                                                    {formErrors.lastName.length > 0 && (
                                                        <span style={{ color: 'red' }} className="errorMessage">{formErrors.lastName}</span>
                                                    )}
                                                </div>

                                                <div class=" col-md-6">
                                                    <div className="panid">
                                                        <label htmlFor="password">PAN Card No *</label>
                                                        <MDBInput style={{ borderColor: 'gray', borderWidth: 1, maxWidth: '450px' }}
                                                            className={formErrors.panid.length > 0 ? "error" : null}
                                                            hint="PAN Card No."
                                                            type="panid"
                                                            name="panid"
                                                            noValidate
                                                            onChange={this.handleChange}
                                                        />
                                                        {formErrors.panid.length > 0 && (
                                                            <span style={{ color: 'red' }} className="errorMessage">{formErrors.panid}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div className="mobileNo">
                                                        <label htmlFor="mobileNo">Mobile No *</label>
                                                    </div>
                                                    <div>
                                                        <MDBInput style={{ borderColor: 'gray', borderWidth: 1, maxWidth: '450px' }}
                                                            className={formErrors.mobileNo.length > 0 ? "error" : null}
                                                            hint="Mobile No"
                                                            type="mobileNo"
                                                            name="mobileNo"
                                                            noValidate
                                                            onChange={this.handleChange}
                                                        />
                                                        {formErrors.mobileNo.length > 0 && (
                                                            <span style={{ color: 'red' }} className="errorMessage">{formErrors.mobileNo}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div className="email">
                                                        <label htmlFor="email">UserName/Email ID *</label>
                                                    </div>
                                                    <div>
                                                        <MDBInput style={{ borderColor: 'gray', borderWidth: 1, maxWidth: '1050px' }}
                                                            className={formErrors.email.length > 0 ? "error" : null}
                                                            hint={localStorage.getItem("GuestuserEmail")}
                                                            type="email"
                                                            name="email"
                                                            noValidate
                                                            onChange={this.handleChange}
                                                            disabled />
                                                        {formErrors.email.length > 0 && (
                                                            <span style={{ color: 'red' }} className="errorMessage">{formErrors.email}</span>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <button style={{ backgroundColor: "#FFEDD9", width: 150, height: 50, borderRadius: 50 }}
                                                color="danger"
                                                type="button"

                                                onClick={this.handleSubmit}     >
                                                Proceed
                                            </button>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </Grid>
                        </MDBRow>
                    </MDBContainer>
                </Styles>
            </div>
        );
    }
}

