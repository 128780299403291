import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody, MDBInput } from 'mdbreact';
import Header from "../components/Header";
import { breakpoints as bp } from "../GlobalStyle";


export default function Contactusg() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  async function postdata() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "name": name,
      "email": email,
      "subject": subject,
      "message": message
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    await fetch("https://panaah-api.herokuapp.com/contact", requestOptions)
      .then(response => response.text())
      .then(result => alert("Thank you for getting in touch. Our team will contact you shortly."))
      .catch(error => console.log('error', error));

  }

  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col>
            <Card >
              <Card.Body>
                <Card.Title>Agape Public School</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Phase-3, Pakhowal Road, Karnail Singh Road, Ludhiana.</Card.Subtitle>
                <Card.Text>
                  info@panaah.org
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <div class="container contact">
            <div class="row" >

              <div class="col-md-9">
                <div class="contact-form">
                  <div class="form-group">
                    <label class="control-label col-sm-2" for="fname">Name:</label>
                    <div class="col-sm-10">
                      <MDBInput type="text" class="form-control" id="fname" placeholder="Enter First Name" name="fname"
                        onChange={(e) => setName(e.target.value)}></MDBInput>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label col-sm-2" for="lname">Email:</label>
                    <div class="col-sm-10">
                      <MDBInput type="text" class="form-control" id="lname" placeholder="Enter Last Name" name="lname"

                        onChange={(e) => setEmail(e.target.value)}> </MDBInput>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label col-sm-2" for="email">Subject:</label>
                    <div class="col-sm-10">
                      <MDBInput type="email" class="form-control" id="email" placeholder="Enter email" name="email"
                        onChange={(e) => setSubject(e.target.value)}></MDBInput>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label col-sm-2" for="comment">Message:</label>
                    <div class="col-sm-10">
                      <textarea
                        onChange={(e) => setMessage(e.target.value)} class="form-control" rows="5" id="comment"></textarea>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="col-sm-offset-2 col-sm-10">
                      <Button style={{ backgroundColor: "#FFEDD9", width: 150, height: 50, borderRadius: 50 }} type="submit" class="btn btn-default" onClick={postdata}>Submit</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Row>


      </Container>

    </div>
  );
}
